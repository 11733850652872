import React from "react"

import Container from "@ecom/ui/components/Container"
import SecondaryPageLayout from "../components/Layouts/secondaryPage"
import Footer from "../components/Footer"

export default function NotFoundPage() {
  return (
    <SecondaryPageLayout>
      <Container style={{ flex: "1 0 auto" }}>
        <h1 style={{ textAlign: "center", fontSize: 30, fontWeight: 500, margin: "50px 0" }}>
          Страница не найдена
        </h1>
      </Container>
      <Footer shortText="" phones={["8 800 700-66-96"]} />
    </SecondaryPageLayout>
  )
}
